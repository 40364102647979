import dayjs from 'dayjs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

interface HeroProps {
  image: any
  title: string
  writers: string | null
  reviewers: string | null
  imageSource?: string
  date: string
}

const Hero = ({
  image,
  title,
  writers,
  reviewers,
  imageSource,
  date,
}: HeroProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-1">
        <GatsbyImage image={getImage(image) as any} alt="Hero image" />
        {imageSource && (
          <p className="text-gray-300 caption-sm md:caption">
            Nguồn: {imageSource}
          </p>
        )}
      </div>
      <div className="flex flex-col space-y-4">
        <h1 className="heading-4 md:heading-2">{title}</h1>
        <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-6 md:flex-row">
          <p className="text-gray-400 caption md:subheading-5">
            Người viết:{' '}
            <span className="text-gray-600">{writers}</span>
          </p>
          <p className="text-gray-400 caption md:subheading-5">
            Người kiểm duyệt:{' '}
            <span className="text-gray-600">{reviewers}</span>
          </p>
          <p className="block text-gray-400 caption md:subheading-5 md:hidden">
            Cập nhật:{' '}
            <span className="text-gray-600">
              {dayjs(date).format('DD-MM-YYYY')}
            </span>
          </p>
        </div>
        <div className="hidden md:flex">
          <p className="text-gray-400 caption md:subheading-5">
            Cập nhật:{' '}
            <span className="text-gray-600">
              {dayjs(date).format('DD-MM-YYYY')}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Hero
